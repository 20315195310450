<template>
    <div v-editable="blok">
        <StoryblokComponent
            v-for="item in blok.body"
            :key="item._uid"
            :blok="item"
        />
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { trackAscPageViewEvent } from '~/composables/asc/events/Pageview'

const props = defineProps({
    blok: {
        type: Object as PropType<Record<string, any>>,
        required: true,
    },
})

if (props.blok?.asc_page_type) {
    useEventStore().setASCPageType(props.blok?.asc_page_type)
}

trackAscPageViewEvent()
</script>
